/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

// FIT VIDS, FITVIDS
(function($) {
jQuery(document).ready(function($){

  // Target your .container, .wrapper, .post, etc.
  $(".post").fitVids();
  $(".panel-heading").fitVids();
  $(".entry-content").fitVids();
  $(".featured-image").fitVids();
  $(".featured-video").fitVids();
});

})(jQuery);

// MOBILE NAV OVERLAY


$('.open-overlay').click(function() {
 //$('.open-overlay').css('pointer-events', 'none');
 var overlay_navigation = $('.overlay-navigation'),
 top_bar = $('.bar-top'),
 middle_bar = $('.bar-middle'),
 bottom_bar = $('.bar-bottom');
 overlay_navigation.toggleClass('overlay-active');
 if (overlay_navigation.hasClass('overlay-active')) {

   top_bar.removeClass('animate-out-top-bar').addClass('animate-top-bar');
   middle_bar.removeClass('animate-out-middle-bar').addClass('animate-middle-bar');
   bottom_bar.removeClass('animate-out-bottom-bar').addClass('animate-bottom-bar');
   overlay_navigation.removeClass('overlay-slide-up').addClass('overlay-slide-down');
   overlay_navigation.velocity('transition.slideLeftIn', {
     duration: 0,
     delay: 0,
     begin: function() {
       $('nav.nav-primary ul li').velocity('transition.perspectiveLeftIn', {
         stagger: 0,
         delay: 0,
         complete: function() {
           $('nav.nav-primary ul li a, nav.nav-primary ul li span').velocity({
             opacity: [1, 0],
           }, {
             delay: 0,
             duration: 0
           });
           // $('.open-overlay').css('pointer-events', 'auto');
         }
       });
     }
   });

 } else {
   // $('.open-overlay').css('pointer-events', 'none');
   top_bar.removeClass('animate-top-bar').addClass('animate-out-top-bar');
   middle_bar.removeClass('animate-middle-bar').addClass('animate-out-middle-bar');
   bottom_bar.removeClass('animate-bottom-bar').addClass('animate-out-bottom-bar');
   overlay_navigation.removeClass('overlay-slide-down').addClass('overlay-slide-up');
   $('nav.nav-primary ul li').velocity('transition.slideRightOut', {
     stagger: 0,
     delay: 0,
     complete: function() {
       overlay_navigation.velocity('transition.fadeOut', {
         delay: 0,
         duration: 0,
         complete: function() {
           $('nav.nav-primary ul li a, nav.nav-primary ul li span').velocity({
             opacity: [0, 1],
           }, {
             delay: 0,
             duration: 0
           });
           // $('.open-overlay').css('pointer-events', 'auto');
         }
       });
     }
   });
 }
});
